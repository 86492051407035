import Api from '@/apis/index'

export default class Server extends Api {
  getWxSettingInfos () {
    return this.$http.get(`${Api.path.apiCrmOS}partner/${window.global._auth.userInfo.partnerId}/grantinfo`)
  }

  postWxSetingInfo (type, o) {
    return this.$http.post(`${Api.path.apiCrmOS}partner/${window.global._auth.userInfo.partnerId}/type/${type}/grantinfos`, o)
  }
}
