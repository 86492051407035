<template>
	<div class="app-set">
		<div class="form-wrap" :style="{'height': wrapHeight + 'px'}">
			<jw-form ref="form" class="max" v-bind="form" />
		</div>
		<div class="submit">
			<el-button type="primary" @click="handlerSubmit">保存</el-button>
		</div>
	</div>
</template>
<script lang="jsx">
import Server from './apis'
import UeEditor from './ueeditor.vue'
export default {
  data () {
    const wrapHeight = document.documentElement.clientHeight
    return {
      form: {
        bind: {
          props: {
            labelWidth: '190px',
            rules: {
              auto: true,
              agreement: {
                required: true
              },
              privacy: {
                required: true
              },
              service: {
                required: true
              },
              max: {
                required: true
              },
              monthVip: {
                required: true
              },
              seasonVip: {
                required: true
              },
              yearVip: {
                required: true
              },
              taxPoint: {
                required: true
              },
              firstLevel: {
                required: true
              },
              secondLevel: {
                required: true
              },
              description: {
                required: true
              }
            }
          }
        },
        data: [
          {
            label: '客服电话',
            tag: 'el-input',
            prop: 'service',
            bind: {
              on: {
                input: (e) => {
                  this.$refs.form.$setValueField('service', 'empty')
                  this.$nextTick(() => {
                    this.$refs.form.$setValueField('service', e.replace(/[^0-9-]/g, ''))
                  })
                }
              },
              attrs: {
                placeholder: '请输入客服电话'
              }
            }
          },
          {
            label: '每天阅读简历最大数量',
            prop: 'max',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.max}
                unit='份'
                position='suffix'
                placeholder='1~999999'
                max={999999}
                min={1}
                polishing={false}
              />
            }
          },
          {
            label: '月度VIP金额',
            prop: 'monthVip',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.monthVip}
                unit='元'
                position='suffix'
                placeholder='1~999999'
                max={999999}
                min={1}
              />
            }
          },
          {
            label: '季度VIP金额',
            prop: 'seasonVip',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.seasonVip}
                unit='元'
                position='suffix'
                placeholder='1~999999'
                max={999999}
                min={1}
              />
            }
          },
          {
            label: '年度VIP金额',
            prop: 'yearVip',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.yearVip}
                unit='元'
                position='suffix'
                placeholder='1~999999'
                max={999999}
                min={1}
              />
            }
          },
          {
            label: '税率',
            prop: 'taxRate',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.taxRate}
                unit='%'
                position='suffix'
                placeholder='1~100'
                max={999999}
                min={1}
              />
            }
          },
          {
            label: '税征起点',
            prop: 'taxPoint',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.taxPoint}
                unit='元'
                position='suffix'
                placeholder='1~999999'
                max={999999}
                min={1}
              />
            }
          },
          {
            label: '一级分佣比率',
            prop: 'firstLevel',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.firstLevel}
                unit='%'
                position='suffix'
                placeholder='1~100'
                max={999999}
                min={0}
              />
            }
          },
          {
            label: '二级分佣比率',
            prop: 'secondLevel',
            render: (h, store, value) => {
              return <jw-number
                v-model={value.secondLevel}
                unit='%'
                position='suffix'
                placeholder='1~100'
                max={999999}
                min={0}
              />
            }
          },
          {
            label: '用户协议',
            prop: 'agreement',
            render: (h, store, value) => {
              return <UeEditor v-model={value.agreement}></UeEditor>
            }
          },
          {
            label: '隐私政策',
            prop: 'privacy',
            render: (h, store, value) => {
              return <UeEditor v-model={value.privacy}></UeEditor>
            }
          },
          {
            label: '产品说明',
            prop: 'description',
            render: (h, store, value) => {
              return <UeEditor v-model={value.description}></UeEditor>
            }
          }
        ],
        value: {
          agreement: '',
          privacy: '',
          service: '',
          max: '',
          monthVip: '39.9',
          seasonVip: '99.9',
          yearVip: '299',
          taxRate: '10',
          taxPoint: '800',
          firstLevel: '0',
          secondLevel: '0',
          description: ''
        }
      },

      wrapHeight: wrapHeight - 240
    }
  },
  mounted () {
    this.$apis = new Server()
    this.$apis.getWxSettingInfos().then(res => {
      console.log('qqqqqqqqqqqq,r', res)
      this.$refs.form.$setValue({
        agreement: res.data.agreement || '',
        privacy: res.data.privacy || '',
        service: res.data.service || '',
        description: res.data.description || '',
        max: res.data.max || '',
        monthVip: res.data.monthVip || '39.9',
        seasonVip: res.data.seasonVip || '99.9',
        yearVip: res.data.yearVip || '299',
        taxRate: res.data.taxRate || '10',
        taxPoint: res.data.taxPoint ?? '800',
        firstLevel: res.data.firstLevel ?? '0',
        secondLevel: res.data.secondLevel ?? '0'
      })
    })
  },
  methods: {
    handlerSubmit () {
      this.$refs.form.$validate(valid => {
        if (valid) {
          const data = this.$refs.form.$getValue()
          this.$apis.postWxSetingInfo(7, data).then(res => {
            this.$message.success('已保存')
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.app-set {
  position: relative;
  padding: 60px 0;
}

.form-wrap {
  overflow-y: auto;
}

.submit {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 1;
  border-top: 1px solid #efefef;
}
</style>
