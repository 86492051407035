<template>
	<rich-editor
		ref="editor"
		:value="content"
		:action="uploadUrl"
		ueditor-home-url="/ueditor"
		:on-success="onSuccess"
		:config="config"
		:buttons="buttons"
		:before-upload="beforeUpload"
		:on-button-click="ButtonClick"
		@input="contentInput"
		@ready="ready"
		/>
</template>
<script>
import Server from '@/apis/index'
import RichEditor from '@jw/vue-components-v2/packages/rich-editor'
export default {
  components: {
    RichEditor
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editor: '',
      content: '',
      uploadUrl: '',
      buttons: [],
      config: {
        initialFrameHeight: 600,
        autoHeightEnabled: false,
        initialFrameWidth: 800,
        autoFloatEnabled: false,
        topOffset: 50,
        toolbars: [[
          'source', // 源代码
          '|',
          'undo', // 撤销
          'redo', // 重做
          '|',
          'fontfamily', // 字体
          'fontsize', // 字号
          'forecolor', // 字体颜色
          'backcolor', // 背景色
          'bold', // 加粗
          'italic', // 斜体
          'underline', // 下划线
          'strikethrough', // 删除线
          'subscript', // 下标
          'superscript', // 上标
          '|',
          'justifyleft', // 居左对齐
          'justifyright', // 居右对齐
          'justifycenter', // 居中对齐
          'justifyjustify', // 两端对齐
          'insertorderedlist', // 有序列表
          'insertunorderedlist', // 无序列表
          'horizontal', // 分隔线
          '|',
          'formatmatch', // 格式刷
          'removeformat', // 清除格式
          'pasteplain', // 纯文本粘贴模式
          '|',
          'link', // 超链接
          // '|',
          'fullscreen' // 全屏
        ]]
      }
    }
  },
  watch: {
    value (newval) {
      this.content = newval
    }
  },
  mounted () {
    this.uploadUrl = Server.path.apiMps + '/upload'
  },
  methods: {
    ready (editor) {
      this.eidtorReady(editor)
    },
    contentInput (value) {
      console.log('333333333333333')
      this.content = value
      this.$emit('change', value)
    },
    onSuccess (response, file) {
      const { name, richEditorFileType } = file.raw
      const url = this.getUrl(response, file)
      let html = ''
      if (richEditorFileType === 'image') {
        html = `<img title="${file.name}" src="${url}?x-image-process=image/interlace,1/quality,q_70" alt="${name}">`
      } else if (richEditorFileType === 'audio') {
        html = `&nbsp;<audio src="${url}" title="${file.name}" controls="controls"></audio>&nbsp;`
      } else if (richEditorFileType === 'video') {
        html = `&nbsp;<video src="${url}" title="${file.name}" controls="controls"></video>&nbsp;`
      }
      return html
    },
    getUrl (res, file) {
      return res.body.customUrl
    },
    eidtorReady (editor) {
      this.editor = editor
    },
    ButtonClick (name, command, uploadFile) {
      uploadFile()
    },
    beforeUpload (file, from, name) {
      return false
    },
    updateAutoFloat () {
      this.editor.updateAutoFloat()
    }
  }
}
</script>
